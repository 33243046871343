.add-event-form {
  .react-datepicker-wrapper {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;

    .date-section {
      .col-md-6:last-child {
        margin-top: 1rem;
      }
    }
  }
}
